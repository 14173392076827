@charset 'UTF-8';
@import 'main/src/constants';

ul {
  padding-left: 0;

  li {
    list-style: none;
    margin-left: 0;
  }
}

.awards-and-features-section {
  #animated-heart {
    top: 0;
    right: 0;
    transform: translateY(-50%);
    $width: percentage(280px / $desktop-inner);
    @include decoration-animation($width);
    margin-bottom: percentage(-115px / $design-max);
    margin-right: percentage(120px / $design-max);

    @include phone {
      display: none;
    }
  }

  .awards-and-features-inner,
  .widont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .awards-and-features-inner {
    max-width: $desktop-inner + $desktop-margin;
    margin-left: auto;
    margin-right: auto;

    .award-or-feature {
      @include inline-block;  // fallback
      height: auto;
      width: auto;
      min-width: 0;

      $max: 130px;
      $min: 70px;
      @include desktop-and-tablet-landscape {
        margin-left: $desktop-margin / $design-max * 100vw;
        margin-bottom: $m5 / $design-max * 100vw;

        &.portrait {
          max-height: $max / $design-max * 100vw;
          max-width: $min / $design-max * 100vw;
        }

        &.square {
          max-height: ($max + $min) / 2 / $design-max * 100vw;
          max-width: ($max + $min) / 2 / $design-max * 100vw;
        }

        &.landscape {
          max-width: $max / $design-max * 100vw;
          max-height: $min / $design-max * 100vw;
        }
      }

      @include above-design-max {
        margin-bottom: $m5;
        margin-left: $desktop-margin;

        &.portrait {
          max-height: $max;
          max-width: $min;
        }

        &.square {
          max-height: ($max + $min) / 2;
          max-width: ($max + $min) / 2;
        }

        &.landscape {
          max-width: $max;
          max-height: $min;
        }
      }

      $max: 90px;
      $min: 49px;
      @include tablet-portrait {
        margin-left: $tablet-margin / $tablet-portrait * 50vw;
        margin-right: $tablet-margin / $tablet-portrait * 50vw;
        margin-bottom: $m5 / $tablet-portrait * 100vw;

        &.portrait {
          max-height: $max / $tablet-portrait * 100vw;
          max-width: $min / $tablet-portrait * 100vw;
        }

        &.square {
          max-height: ($max + $min) / 2 / $tablet-portrait * 100vw;
          max-width: ($max + $min) / 2 / $tablet-portrait * 100vw;
        }

        &.landscape {
          max-width: $max / $tablet-portrait * 100vw;
          max-height: $min / $tablet-portrait * 100vw;
        }
      }

      @include phone-landscape {
        margin-left: $tablet-margin / $phone-max * 50vw;
        margin-right: $tablet-margin / $phone-max * 50vw;
        margin-bottom: $m5 / $phone-max * 100vw;

        &.portrait {
          max-height: $max / $phone-max * 100vw;
          max-width: $min / $phone-max * 100vw;
        }

        &.square {
          max-height: ($max + $min) / 2 / $phone-max * 100vw;
          max-width: ($max + $min) / 2 / $phone-max * 100vw;
        }

        &.landscape {
          max-width: $max / $phone-max * 100vw;
          max-height: $min / $phone-max * 100vw;
        }
      }

      $max: 78px;
      $min: 43px;
      @include phone-portrait {
        margin-left: $phone-margin / $phone-portrait * 100vw;
        margin-right: $phone-margin / $phone-portrait * 100vw;
        margin-bottom: $m5 / $phone-portrait * 100vw;

        &.portrait {
          max-height: $max / $phone-portrait * 100vw;
          max-width: $min / $phone-portrait * 100vw;
        }

        &.square {
          max-height: ($max + $min) / 2 / $phone-portrait * 100vw;
          max-width: ($max + $min) / 2 / $phone-portrait * 100vw;
        }

        &.landscape {
          max-width: $max / $phone-portrait * 100vw;
          max-height: $min / $phone-portrait * 100vw;
        }
      }

      &.last-child {
        margin-right: 0;

        @include above-design-max {
          margin-right: 0;
        }
      }
    }
  }
}
